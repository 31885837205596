<template>
  <div>
    <static-fullscreen-card @sendHeight="cardHeight = $event" :title="false">
      <template v-slot:header></template>
      <v-row>
        <v-col v-if="$root.profile.role == 1" cols="12">
          <h2 class="text-center">
            Тестовый или оплаченный период закончился.

            <p>Для продолжения работы, выберите тариф и произведите оплату</p>
          </h2>
        </v-col>

        <v-col cols="12" sm="4" v-for="t in tarif2" :key="t.name">
          <v-card class="text-center">
            <v-card-title class="d-block text-center">
              {{ t.title }}
            </v-card-title>
            <v-card-text>
              <p v-for="l in t.text">
                {{ l }}
              </p>
              <div><span class="plan-sign color-darkgrey">$</span>{{ t.price }}</div>
            </v-card-text>
            <v-card-actions class="d-block text-center">
              <div v-if="t.href.includes('yoomoney')" class="text-center">
                <iframe :src="t.href" width="330" height="50" frameborder="0" allowtransparency="true" scrolling="no"></iframe>
              </div>
              <v-btn v-else rounded class="mb-5 px-5" color="primary" @click.stop="onClick(t)">Техподдержка</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" v-for="t in video" :key="t.name">
          <v-card class="text-center">
            <v-card-title v-if="t.title" class="d-block text-center">
              {{ t.title }}
            </v-card-title>
            <v-card-text>
              <div v-if="t.file" class="video-container" style="width: 100%">
                <video controls playsinline style="width: 100%; height: auto; display: block; margin: 0 auto" crossorigin="anonymous">
                  <source :src="`https://my.unrealty.pro/video/` + t.file" type="video/mp4" crossorigin="anonymous" />
                </video>
              </div>
              <div v-if="t.text">
                <p v-for="l in t.text">
                  {{ l }}
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </static-fullscreen-card>

    <portal to="v-main"> </portal>
  </div>
</template>

<script>
import { getAccess, genModel } from "@/components/mixings";
export default {
  mixins: [genModel],
  components: {},
  data() {
    return {
      tarif2: this.$store.state.config.data.config.dictionary?.tarifs || [],
      video: this.$store.state.config.data.config.dictionary?.video || [],
      tarif: [
        { name: "base", title: "Базовый", price: 22, text: ["line1", "line2 "] },
        { name: "3day", title: "3 Дня", price: 32, text: "line1 <br> line2 " },
        { name: "master", title: "Мастер", price: 92, text: "line1 <br> line2 " },
      ],
    };
  },
  created() {
    this.$root.title = "Главная";
  },
  beforeDestroy() {},

  computed: {},
  methods: {
    onClick(t) {
      window.open(t.href, "_blank");
    },
  },
};
</script>
